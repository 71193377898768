import React from 'react';
import { Link } from "gatsby"
import '../styles/global.css';

export default ({ data }) => { 
  return (
    <nav class="navbar">
        <div class="container flex">
            <div class="title">
                <Link to="/" style={{ display: "flex", alignItems: "center" }}>
                    { data && (
                       <img src={data.profilePic.childImageSharp.fluid.src} style={{ borderRadius: "50%", width: "2.5rem", marginRight: "0.5rem"}} />
                    )}
                    <span>Nils Buschhüter</span>
                </Link>
            </div>
            <div class="nav" style={{ display: "flex", alignItems: "center" }}>
                <Link to="/blog">Blog</Link>
                <Link to="/about">About me</Link>
            </div>
        </div>
       
   </nav>
  );
}